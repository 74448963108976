<template>
  <div>
     <h1><div id="inTurnFadingTextG"><div id="inTurnFadingTextG_1" class="inTurnFadingTextG">L</div><div id="inTurnFadingTextG_2" class="inTurnFadingTextG">o</div><div id="inTurnFadingTextG_3" class="inTurnFadingTextG">a</div><div id="inTurnFadingTextG_4" class="inTurnFadingTextG">d</div><div id="inTurnFadingTextG_5" class="inTurnFadingTextG">i</div><div id="inTurnFadingTextG_6" class="inTurnFadingTextG">n</div><div id="inTurnFadingTextG_7" class="inTurnFadingTextG">g</div></div></h1>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SubscriptionNewFlow",
  data: () => ({
    loading: true,
  }),
  created() {
    console.log(this.$route.params)
    this.getUserInfoById(this.$route.params.id);
    
  },
  mounted() {
     
  },
  methods: {
    getUserInfoById(id) {
      this.axios({
        method: "get",
        url: `/get_user_info_by_id/${id}`,
      }).then((response) => {
        this.manageUserAuth(response.data);
      });
    },
    manageUserAuth(data) {
      this.$store.commit("setAccessToken", {
        token: data.access_token,
        expires: data.expires_in,
      });
      this.$nextTick(() => {
        this.$store.dispatch("getUserData").then((re) => {
          this.loading = false;
          console.log(this.$route.params)
          if (re.data.user_status === 1) {
            if (
              re.data.billing_status === "Inactive" ||
              re.data.billing_status === "Expired"
            ) {
              if (typeof this.$route.params.plan !== "undefined") {
                this.$router.push(`/subscription/${this.$route.params.plan}`);
              } else {
               this.$router.push(`/subscription`);
              }
              this.$forceUpdate();
            } else if (
              re.data.billing_status === "Cancelled" ||
              re.data.billing_status === "Failed"
            ) {
              window.BCookie.remove("BC-Auth-Email");
              this.errorsForm.accountCancelled = true;
              this.destroyAccessToken();
              this.goFirstStep();
            } else if (re.data.billing_status === "VerifyRequired") {
              window.BCookie.remove("BC-Auth-Email");
              this.errorsForm.accountNotVerified = true;
              this.errorsForm.email =
                "This account is not verified, please check your email.";
              this.userId = re.data.id;
              this.destroyAccessToken();
              this.goFirstStep();
            } else {
              const redirectUri = window.BCookie.check("redirect_path")
                ? window.BCookie.get("redirect_path")
                : "/";

              window.Ls.remove("BC-Auth-Remember");
              window.Ls.remove("sub-user-info");
              this.$router.push(redirectUri);
              this.$forceUpdate();
            }
          } else {
            window.BCookie.remove("BC-Auth-Email");
            this.errorsForm.accountCancelled = true;
            this.destroyAccessToken();
            this.goFirstStep();
          }
        });
      });
    },
  },
 
};
</script>
<style scoped>
#inTurnFadingTextG{
	width:234px;
	margin:auto;
}

.inTurnFadingTextG{
	color:rgb(0,0,0);
	font-family:Arial;
	font-size:24px;
	text-decoration:none;
	font-weight:normal;
	font-style:normal;
	float:left;
	animation-name:bounce_inTurnFadingTextG;
		-o-animation-name:bounce_inTurnFadingTextG;
		-ms-animation-name:bounce_inTurnFadingTextG;
		-webkit-animation-name:bounce_inTurnFadingTextG;
		-moz-animation-name:bounce_inTurnFadingTextG;
	animation-duration:2.09s;
		-o-animation-duration:2.09s;
		-ms-animation-duration:2.09s;
		-webkit-animation-duration:2.09s;
		-moz-animation-duration:2.09s;
	animation-iteration-count:infinite;
		-o-animation-iteration-count:infinite;
		-ms-animation-iteration-count:infinite;
		-webkit-animation-iteration-count:infinite;
		-moz-animation-iteration-count:infinite;
	animation-direction:normal;
		-o-animation-direction:normal;
		-ms-animation-direction:normal;
		-webkit-animation-direction:normal;
		-moz-animation-direction:normal;
}

#inTurnFadingTextG_1{
	animation-delay:0.75s;
		-o-animation-delay:0.75s;
		-ms-animation-delay:0.75s;
		-webkit-animation-delay:0.75s;
		-moz-animation-delay:0.75s;
}
#inTurnFadingTextG_2{
	animation-delay:0.9s;
		-o-animation-delay:0.9s;
		-ms-animation-delay:0.9s;
		-webkit-animation-delay:0.9s;
		-moz-animation-delay:0.9s;
}
#inTurnFadingTextG_3{
	animation-delay:1.05s;
		-o-animation-delay:1.05s;
		-ms-animation-delay:1.05s;
		-webkit-animation-delay:1.05s;
		-moz-animation-delay:1.05s;
}
#inTurnFadingTextG_4{
	animation-delay:1.2s;
		-o-animation-delay:1.2s;
		-ms-animation-delay:1.2s;
		-webkit-animation-delay:1.2s;
		-moz-animation-delay:1.2s;
}
#inTurnFadingTextG_5{
	animation-delay:1.35s;
		-o-animation-delay:1.35s;
		-ms-animation-delay:1.35s;
		-webkit-animation-delay:1.35s;
		-moz-animation-delay:1.35s;
}
#inTurnFadingTextG_6{
	animation-delay:1.5s;
		-o-animation-delay:1.5s;
		-ms-animation-delay:1.5s;
		-webkit-animation-delay:1.5s;
		-moz-animation-delay:1.5s;
}
#inTurnFadingTextG_7{
	animation-delay:1.64s;
		-o-animation-delay:1.64s;
		-ms-animation-delay:1.64s;
		-webkit-animation-delay:1.64s;
		-moz-animation-delay:1.64s;
}




@keyframes bounce_inTurnFadingTextG{
	0%{
		color:rgb(0,0,0);
	}

	100%{
		color:rgb(255,255,255);
	}
}

@-o-keyframes bounce_inTurnFadingTextG{
	0%{
		color:rgb(0,0,0);
	}

	100%{
		color:rgb(255,255,255);
	}
}

@-ms-keyframes bounce_inTurnFadingTextG{
	0%{
		color:rgb(0,0,0);
	}

	100%{
		color:rgb(255,255,255);
	}
}

@-webkit-keyframes bounce_inTurnFadingTextG{
	0%{
		color:rgb(0,0,0);
	}

	100%{
		color:rgb(255,255,255);
	}
}

@-moz-keyframes bounce_inTurnFadingTextG{
	0%{
		color:rgb(0,0,0);
	}

	100%{
		color:rgb(255,255,255);
	}
}
</style>
<!-- NEMESIS ADD END -->
<style lang="less" scoped src="../../assets/less/custom/auth.less"></style>
<style lang="less" src="../../assets/less/custom/pages.less"></style>
